import { h } from 'preact';
import { useHasAccessPolicies } from '@didomi/utility-react';
import { ACCESS_POLICIES_PER_SIDENAV_ID } from '../../constants/sidenav-access-policies';

interface RoleAccessProps {
  navItemId: string;
  children: any;
}

const RoleAccessCheck = ({
  accessPolicies,
  children,
}: {
  accessPolicies: string[];
  children: any;
}) => {
  const { hasAccess } = useHasAccessPolicies(accessPolicies);

  return hasAccess ? children : null;
};

/*
 * Check whether User has policies that are required for nav-item
 */
const RoleAccess = ({ navItemId, children }: RoleAccessProps) => {
  const itemAccessPolicies = ACCESS_POLICIES_PER_SIDENAV_ID[navItemId];

  // render children in nav item doesn't have required policies
  if (!itemAccessPolicies) return children;

  return (
    <RoleAccessCheck accessPolicies={itemAccessPolicies}>
      {children}
    </RoleAccessCheck>
  );
};

export default RoleAccess;
