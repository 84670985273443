import { h } from 'preact';
import { useMemo } from 'preact/hooks';
import { useCustomFeature } from '@didomi/utility-react';

interface CustomFeatureProps {
  flags?: { flag: string; renderIfDisabled?: boolean }[] | null | undefined;
  children: any;
}

const CustomFeatureCheck = ({ flags, children }: CustomFeatureProps) => {
  const featureFlagsResult = flags.map((flag) => useCustomFeature(flag.flag));

  const shouldRender = useMemo(
    () =>
      featureFlagsResult.reduce(
        (result, flagDetails, i) =>
          result ||
          (flagDetails[0] !== null && flags[i]?.renderIfDisabled
            ? !flagDetails[0]
            : flagDetails[0]),
        false,
      ),
    [featureFlagsResult],
  );

  return shouldRender ? children : null;
};

const CustomFeature = ({ flags, children }: CustomFeatureProps) => {
  if (!flags) return children;

  return <CustomFeatureCheck flags={flags}>{children}</CustomFeatureCheck>;
};

export default CustomFeature;
