import { Fragment, h } from 'preact';
import { useMemo } from 'preact/hooks';
import {
  DidomiIcon,
  DidomiDropdown,
  DidomiOption,
} from '@didomi/ui-atoms-react';
import {
  useOrganizations,
  useActiveOrganization,
  useSPARouter,
} from '@didomi/utility-react';

export const OrganizationSelectionButton = () => {
  const { navigateTo } = useSPARouter();
  const [organizations] = useOrganizations();
  const { organizationId, organization, setOrganization } =
    useActiveOrganization();

  const sortedOrganizations = useMemo(() => {
    return organizations?.length
      ? organizations.sort((orgA, orgB) => {
          if (orgA.name?.toLowerCase() < orgB.name?.toLowerCase()) {
            return -1;
          }
          if (orgA.name > orgB.name) {
            return 1;
          }
          return 0;
        })
      : [];
  }, [organizations]);

  const changeOrganization = (e) => {
    const newOrgId = e.detail.value;
    /* istanbul ignore else */
    if (newOrgId !== organizationId) {
      setOrganization(newOrgId);
      // We navigate to the home
      navigateTo('dashboard');
    }
  };

  if (organizations?.length <= 1) {
    return (
      <div class="mr-xs">
        <div data-cy="active-organization-name" class="hidden">
          {organization?.name}
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <DidomiDropdown
        data-cy="organizations-menu"
        variant="blue"
        distance="xxs"
        distanceX="-xxs"
        for="sidenav-organization-selection-button"
        onSelectionChanged={changeOrganization}
        selectedValue={organization?.name}
        attachTo="sidenav-menu-container"
      >
        <div>
          {sortedOrganizations.map((org) => (
            <DidomiOption itemText={org.name} itemValue={org.id} />
          ))}
        </div>
      </DidomiDropdown>
      <button
        id="sidenav-organization-selection-button"
        class="organization-selection-button h-[60px] pl-s -ml-xxs -mr-xs bg-blue-rightpanel text-white text-body-small rounded-l-full flex items-center"
      >
        <DidomiIcon name="website" />
        <span class="flex-1 ml-xs text-left font-bold truncate">
          {organization?.name}
        </span>
      </button>

      <div data-cy="active-organization-name" class="hidden">
        {organization?.name}
      </div>
    </Fragment>
  );
};
