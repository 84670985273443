import { Fragment, h } from 'preact';
import {
  DidomiIcon,
  DidomiMenu,
  DidomiMenuItem,
  DidomiMenuSeparator,
} from '@didomi/ui-atoms-react';
import {
  useSnackbar,
  useSDK,
  useHubspot,
  useSidenavMethods,
  useUserProfile,
} from '@didomi/utility-react';
import copy from 'copy-text-to-clipboard';

export const NeedHelpMenu = () => {
  const { displaySnackbar } = useSnackbar();
  const { isOpen: isOpenOnMobile, close: closeSidenav } = useSidenavMethods();
  const { isVendorEnabled, showNoticeBanner } = useSDK();
  const [userProfile] = useUserProfile();
  const { initHubspot, showHubspot } = useHubspot();

  const isSelfService = userProfile?.self_register;

  const copyEmail = () => {
    copy('support@didomi.io');
    displaySnackbar(
      'The Support email address has been copied to the clipboard',
      {
        action: { name: 'Ok', closeInAction: true },
      },
    );
  };

  const openChat = () => {
    if (isOpenOnMobile) {
      closeSidenav();
    }
    if (!isVendorEnabled('c:hubspot')) {
      window['didomiEventListeners']?.push({
        event: 'notice.clickagree',
        listener: () => {
          /* istanbul ignore next */
          initHubspot();
        },
      });
      showNoticeBanner();
    } else {
      showHubspot();
    }
  };

  return (
    <Fragment>
      <button
        id="sidenav-menu-help-button"
        data-testid="menu-help-button"
        data-cy="menu-help-menu"
        title="My account"
        class="outline-none! rounded-full border-0 flex items-center focus:(ring-2 ring-offset-2 ring-primary-blue-2)"
      >
        <DidomiIcon
          class="rounded-full border-1 border-neutral-gray-3 bg-neutral-gray-1 w-m h-m flex items-center justify-center"
          name="help"
        />
        <span class="font-sans ml-xxs text-button-small text-primary-blue-6">
          Need help?
        </span>
      </button>
      <DidomiMenu
        for="sidenav-menu-help-button"
        menuTitle="Need help?"
        placement="top"
        distance="xxs"
        attachTop="sidenav-menu-container"
      >
        <div class="mt-xxs">
          <DidomiMenuItem
            data-cy="didomi-menu-item-help-center"
            iconName="help"
            onClick={() =>
              window.open('https://support.didomi.io/', '_blank')?.focus()
            }
          >
            Help Center
          </DidomiMenuItem>
          <DidomiMenuItem
            iconName="dev-documentation"
            onClick={() =>
              window.open('https://developers.didomi.io/', '_blank')?.focus()
            }
          >
            Developer Docs
          </DidomiMenuItem>

          {!isSelfService && (
            <DidomiMenuItem iconName="chat" onClick={() => openChat()}>
              Chat with us
            </DidomiMenuItem>
          )}

          <DidomiMenuSeparator />
          <DidomiMenuItem
            data-cy="didomi-menu-item-email"
            iconName="email"
            description="support@didomi.io"
            onClick={copyEmail}
          >
            Send an email
          </DidomiMenuItem>
        </div>
      </DidomiMenu>
    </Fragment>
  );
};
