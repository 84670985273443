import {
  useActiveOrganization,
  useFeatureFlag,
  useHasAccessPolicies,
} from '@didomi/utility-react';
import { ACCESS_POLICIES_CONFIG } from '../constants/sidenav-access-policies';

export const useOrgSettingsMenuVisibility = () => {
  // Org
  const { organization } = useActiveOrganization();

  // Feature
  const [isSSOEnabled] = useFeatureFlag('sso');

  // Access
  const { hasAccess: hasAccessToUsers } = useHasAccessPolicies(
    ACCESS_POLICIES_CONFIG.USERS_VIEWER,
  );
  const { hasAccess: hasEditAccessToOrgSettings } = useHasAccessPolicies(
    ACCESS_POLICIES_CONFIG.ORG_SETTINGS_EDITOR,
  );
  const { hasAccess: hasAccessToOrgSettings } = useHasAccessPolicies(
    ACCESS_POLICIES_CONFIG.ORG_SETTINGS_VIEWER,
  );

  // Self Service
  const isSelfService = organization?.self_register;

  return {
    withUsers: hasAccessToUsers,
    withSSO: hasEditAccessToOrgSettings && isSSOEnabled,
    withPrivateApiKey: hasAccessToOrgSettings && !isSelfService,
    withPublicApiKey: !!organization && !isSelfService,
    withOrgSettings: hasAccessToOrgSettings && !!isSelfService,
  };
};
