import { Fragment, h } from 'preact';
import { DidomiIcon, DidomiMenu, DidomiMenuItem } from '@didomi/ui-atoms-react';
import {
  useAuthMethods,
  useUserProfile,
  useSPARouter,
  useActiveOrganization,
  useSidenavMethods,
  useSDK,
} from '@didomi/utility-react';

export const MyProfileMenu = () => {
  const { logout } = useAuthMethods();
  const { isOpen: isOpenOnMobile, close: closeSidenav } = useSidenavMethods();
  const [userProfile] = useUserProfile();
  const { navigateToAbsolute, navigateTo } = useSPARouter();
  const { organizationId } = useActiveOrganization();
  const { showPreferencesBanner } = useSDK();

  const handleAccountSettings = () => {
    if (isOpenOnMobile) {
      closeSidenav();
    }

    if (organizationId) {
      navigateTo('/account/settings');
    } else navigateToAbsolute('/account/settings');
  };

  return (
    <Fragment>
      <button
        id="sidenav-menu-profile-button"
        data-cy="profile-menu"
        data-testid="profile-menu-button"
        class="outline-none! rounded-full border-0 flex items-center focus:(ring-2 ring-offset-2 ring-primary-blue-2)"
      >
        <DidomiIcon
          class="rounded-full border-1 border-neutral-gray-3 bg-neutral-gray-1 w-m h-m flex items-center justify-center"
          name="login"
          title="My account"
        />
        <span class="font-sans ml-xxs text-button-small text-primary-blue-6">
          My account
        </span>
      </button>
      <DidomiMenu
        for="sidenav-menu-profile-button"
        menuTitle="My account"
        menuSubTitle={userProfile?.email}
        placement="top"
        distance="xxs"
        attachTo="sidenav-menu-container"
      >
        <DidomiMenuItem
          class="mt-xxs!"
          onClick={() => handleAccountSettings()}
          data-cy="profile-account-settings"
        >
          Account settings
        </DidomiMenuItem>
        <DidomiMenuItem
          class="mt-xxs!"
          onClick={() => showPreferencesBanner()}
          data-cy="consent-choices"
        >
          Consent choices
        </DidomiMenuItem>
        <DidomiMenuItem
          iconName="logout"
          onClick={() => logout({ federated: userProfile?.isSAML })}
          data-cy="profile-logout"
        >
          Logout
        </DidomiMenuItem>
      </DidomiMenu>
    </Fragment>
  );
};
