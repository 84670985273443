import { h } from 'preact';
import { useMemo } from 'preact/hooks';
import { useReleaseFlag } from '@didomi/utility-react';

interface ReleaseFlagProps {
  flags?: { flag: string; renderIfDisabled?: boolean }[] | null | undefined;
  children: any;
}

const ReleaseFlagCheck = ({ flags, children }: ReleaseFlagProps) => {
  const featureFlagsResult = flags.map((flag) => useReleaseFlag(flag.flag));

  const isLoading = featureFlagsResult.reduce(
    (result, flagResult) => result || flagResult[1],
    false,
  );

  if (isLoading) return null;

  const shouldRender = useMemo(
    () =>
      featureFlagsResult.reduce(
        (result, flagDetails, i) =>
          result ||
          (flagDetails[0] !== null && flags[i]?.renderIfDisabled
            ? !flagDetails[0]
            : flagDetails[0]),
        false,
      ),
    [featureFlagsResult],
  );

  return shouldRender ? children : null;
};

const ReleaseFlag = ({ flags, children }: ReleaseFlagProps) => {
  if (!flags) return children;

  return <ReleaseFlagCheck flags={flags}>{children}</ReleaseFlagCheck>;
};

export default ReleaseFlag;
