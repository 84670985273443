import { h } from 'preact';
import { connect, theme } from '@didomi/ui-foundation';
import { Sidenav } from './components/Sidenav/Sidenav';

import { UtilityProvider } from '@didomi/utility-react';
import * as utility from '@didomi/utility';

if (process.env.NODE_ENV === 'development') {
  require('preact/debug');
}

// Setup Twind for topbar only
connect('.sidenav-root', {
  theme: {
    extend: {
      ...theme.extend,
      colors: {
        ...theme.extend.colors,
        sidenav: {
          background: 'rgba(29, 65, 83, 0.5);',
        },
      },
    },
  },
});

export const SidenavApp = () => {
  return (
    <UtilityProvider utility={utility}>
      <Sidenav />
    </UtilityProvider>
  );
};
