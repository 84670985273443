import {
  AvailableModules,
  modules,
  AccessTypes,
} from '@didomi/authorization-metadata';

/**
 * Access Policies per Module Access Type
 *
 * For every module access type we use in this project, defines the list of policy IDs
 * that are granted this level of access.
 *
 * The lists are defined in @didomi/authorization-metadata,
 * we only alias the `accessTypeAllowedPolicies` property here to make it more concise.
 *
 * Note: since the list includes all policies that can access a given access type,
 * to display/hide a nav item based on "Viewer" access, it's sufficient to reference the
 * `accessTypeAllowedPolicies[AccessTypes.Viewer]` of the module, and we don't need to add the editor
 * policy separately.
 *
 * Eg. For the CMP module, `accessTypeAllowedPolicies[AccessTypes.Viewer]` will have the policies
 * "cmp_viewer", "cmp_editor", "admin",
 * meaning a user in possession of either will have the "CMP Viewer" access type.
 */
export const ACCESS_POLICIES_CONFIG = {
  ANALYTICS_VIEWER:
    modules[AvailableModules.Analytics].accessTypeAllowedPolicies[
      AccessTypes.Viewer
    ],
  USERS_VIEWER:
    modules[AvailableModules.UsersAndRoles].accessTypeAllowedPolicies[
      AccessTypes.Viewer
    ],
  NOTICE_VERSIONS_VIEWER:
    modules[AvailableModules.NoticeVersions].accessTypeAllowedPolicies[
      AccessTypes.Viewer
    ],
  CONSENT_PROOFS_VIEWER:
    modules[AvailableModules.ConsentProofReports].accessTypeAllowedPolicies[
      AccessTypes.Viewer
    ],
  DATA_MANAGER_VIEWER:
    modules[AvailableModules.DataManager].accessTypeAllowedPolicies[
      AccessTypes.Viewer
    ],
  DATA_MANAGER_EDITOR:
    modules[AvailableModules.DataManager].accessTypeAllowedPolicies[
      AccessTypes.Editor
    ],
  ORG_SETTINGS_VIEWER:
    modules[AvailableModules.OrganizationSettings].accessTypeAllowedPolicies[
      AccessTypes.Viewer
    ],
  ORG_SETTINGS_EDITOR:
    modules[AvailableModules.OrganizationSettings].accessTypeAllowedPolicies[
      AccessTypes.Editor
    ],
  CMP_VIEWER:
    modules[AvailableModules.CMP].accessTypeAllowedPolicies[AccessTypes.Viewer],
  COMPLIANCE_REPORT_VIEWER:
    modules[AvailableModules.ComplianceReport].accessTypeAllowedPolicies[
      AccessTypes.Viewer
    ],
  PRIVACY_CENTER_VIEWER:
    modules[AvailableModules.PrivacyCenter].accessTypeAllowedPolicies[
      AccessTypes.Viewer
    ],
  PMP_WIDGETS_VIEWER:
    modules[AvailableModules.PMPWidgets].accessTypeAllowedPolicies[
      AccessTypes.Viewer
    ],
  DSAR_WIDGETS_VIEWER:
    modules[AvailableModules.DSARWidgets].accessTypeAllowedPolicies[
      AccessTypes.Viewer
    ],
  CONFIGURATION_TREE_VIEWER:
    modules[AvailableModules.ConfigurationTrees].accessTypeAllowedPolicies[
      AccessTypes.Viewer
    ],
  USER_REQUEST_VIEWER:
    modules[AvailableModules.UserRequests].accessTypeAllowedPolicies[
      AccessTypes.Viewer
    ],
};

export const ACCESS_POLICIES_PER_SIDENAV_ID = {
  'analytics': ACCESS_POLICIES_CONFIG.ANALYTICS_VIEWER,
  'history-logs': [
    ...ACCESS_POLICIES_CONFIG.NOTICE_VERSIONS_VIEWER,
    ...ACCESS_POLICIES_CONFIG.CONSENT_PROOFS_VIEWER,
  ],
  'data-manager': ACCESS_POLICIES_CONFIG.DATA_MANAGER_VIEWER,
  'consent-notices': ACCESS_POLICIES_CONFIG.CMP_VIEWER,
  'agnostik-consent-notices': ACCESS_POLICIES_CONFIG.CMP_VIEWER,
  'agnostik-compliance-report': ACCESS_POLICIES_CONFIG.COMPLIANCE_REPORT_VIEWER,
  'privacy-center': ACCESS_POLICIES_CONFIG.PRIVACY_CENTER_VIEWER,
  'privacy-requests': [
    ...ACCESS_POLICIES_CONFIG.USER_REQUEST_VIEWER,
    ...ACCESS_POLICIES_CONFIG.DSAR_WIDGETS_VIEWER,
  ],
  'preference-management': [
    ...ACCESS_POLICIES_CONFIG.PMP_WIDGETS_VIEWER,
    ...ACCESS_POLICIES_CONFIG.CONFIGURATION_TREE_VIEWER,
  ],
};
