import { h } from 'preact';
import { useMemo } from 'preact/hooks';
import { useFeatureFlag } from '@didomi/utility-react';
import { FeatureFlagsTypes } from '@didomi/utility';

interface FeatureFlagProps {
  flags?:
    | { flag: FeatureFlagsTypes; renderIfDisabled?: boolean }[]
    | null
    | undefined;
  children: any;
}

const FeatureFlagCheck = ({ flags, children }: FeatureFlagProps) => {
  const featureFlagsResult = flags.map((flag) => useFeatureFlag(flag.flag));

  const isLoading = featureFlagsResult.reduce(
    (result, flagResult) => result || flagResult[1],
    false,
  );

  if (isLoading) return null;

  const shouldRender = useMemo(
    () =>
      featureFlagsResult.reduce(
        (result, flagDetails, i) =>
          result ||
          (flagDetails[0] !== null && flags[i]?.renderIfDisabled
            ? !flagDetails[0]
            : flagDetails[0]),
        false,
      ),
    [featureFlagsResult],
  );

  return shouldRender ? children : null;
};

const FeatureFlag = ({ flags, children }: FeatureFlagProps) => {
  if (!flags) return children;

  return <FeatureFlagCheck flags={flags}>{children}</FeatureFlagCheck>;
};

export default FeatureFlag;
