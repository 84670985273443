import { h } from 'preact';
import { memo, useMemo } from 'preact/compat';
import { useMediaQuery } from '@didomi/helpers-react/hooks';
import {
  useActiveOrganization,
  useSidenavMethods,
} from '@didomi/utility-react';
import { tw } from '@didomi/ui-foundation';
import { NavigationItem } from '../types/NavigationItem.type';

type NavItemProps = {
  item: NavigationItem;
  activePath: string;
  onItemSelected: (event: Event, path: string) => void;
};

// The domain where the SPA is deployed and the assets hosted
const sideNavURL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:9002'
    : window.location.origin + '/sidenav';

export const NavItem = memo(
  ({ item, activePath, onItemSelected }: NavItemProps) => {
    const { isOpen: isOpenOnMobile, close: closeSidenav } = useSidenavMethods();
    const isSmallScreen = useMediaQuery('(max-width: 768px)');
    const { name, id, icon, path, isMainPage, disabled, dataTracking } = item;
    const shouldComplianceReportNavItemBeActive = (path, activePath) => {
      return (
        activePath.includes('compliance-report') &&
        path.includes('agnostik-compliance-report')
      );
    };
    const shouldConsentNoticeNavItemBeActive = (path, activePath) => {
      return (
        activePath.includes('smart-vendor-list') &&
        path.includes('consent-notices')
      );
    };
    const currentState = useMemo(() => {
      const isMainPageActive = isMainPage && activePath === '/';
      if (disabled) {
        return 'disabled';
      }
      if (
        shouldComplianceReportNavItemBeActive(path, activePath) ||
        shouldConsentNoticeNavItemBeActive(path, activePath) ||
        path === activePath ||
        isMainPageActive
      ) {
        return 'active';
      }
      return 'default';
    }, [activePath, path]);

    /* istanbul ignore next */
    const handleKeyDown = (e: KeyboardEvent, path: string) => {
      if (e.key === 'Enter' || e.code === 'Space') {
        handleItemSelection(e, path);
        // @ts-ignore
        e.target?.blur();
      }
    };

    const handleItemSelection = (
      e: MouseEvent | KeyboardEvent,
      path: string,
    ) => {
      if (isOpenOnMobile) {
        closeSidenav();
      }
      onItemSelected(e, path);
    };

    const { organizationId, organization } = useActiveOrganization();
    const basePath = `${window.location.origin}/${organizationId}`;

    if (
      (!organization && !item.isMainPage) ||
      (organization?.self_register && !item.selfRegister)
    )
      return null;

    return (
      <li
        key={name}
        class="flex items-center justify-center w-full mt-[12px]"
        data-cy={`sidenav-${item.id}`}
      >
        <a
          href={`${basePath}${path}`}
          class={tw(
            'w-full flex items-center h-auto no-underline',
            'border-1 border-solid border-white rounded-full',
            'focus:(ring-primary-blue-2 ring-2) hover:bg-neutral-gray-1',
            'p-0 m-0 outline-none cursor-pointer',
            {
              'ring-1 ring-inset ring-primary-blue-1 rounded-full hover:(ring-2 ring-primary-blue-2)':
                currentState === 'active',
              'cursor-default pointer-events-none': currentState === 'disabled',
            },
          )}
          title={name}
          data-tracking={dataTracking}
          tabIndex={disabled ? -1 : 0}
          data-testid={id}
          onClick={(e) => !disabled && handleItemSelection(e, path)}
          onKeyDown={(e) => handleKeyDown(e, path)}
        >
          <div class="h-11 w-11 min-w-[44px] flex items-center justify-center overflow-x-hidden">
            <img
              src={`${sideNavURL}/assets/images/icons/${icon}-${currentState}.svg`}
              alt=""
            />
          </div>
          <span
            class={tw(
              'text-body-small mr-0 invisible w-0 opacity-0 md:(group-sidenav-hover:(mr-xs visible w-full opacity-100)) transition-all delay-3000 ease-in-out whitespace-nowrap',
              {
                'mr-xs visible! w-full opacity-100!':
                  isOpenOnMobile && isSmallScreen,
              },
            )}
          >
            {name}
          </span>
        </a>
      </li>
    );
  },
);
